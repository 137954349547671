import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import clip from 'text-clipper'
import removeMarkdown from 'remove-markdown'

import { Title } from '../../title'
import { Box } from '../../box'
import arrowRightImg from '../../../assets/arrow-right.svg'
import { breakpoint } from '../../../config'

const Text = styled.div`
  margin-bottom: 1rem;

  @media ${breakpoint.tablet} {
    margin-bottom: 1.5rem;
  }
`

const Icon = styled.img`
  display: block;
  width: 32px;
  height: auto;
  transition: transform 0.2s ease;
  margin-bottom: 6px;

  @media ${breakpoint.tablet} {
    width: 40px;
  }
`

const Link = styled(GatsbyLink)`
  display: flex;
  text-decoration: none;

  &:hover {
    color: inherit;
  }

  &:hover ${Icon} {
    transform: translateX(10px);
  }

  @media ${breakpoint.tablet} {
    height: 100%;
  }
`

export function Preview({ post }) {
  return (
    <Link to={`/news/${post.slug}`}>
      <Box>
        <Title heading="h2" size={2} margin>
          {post.title}
        </Title>
        <Text>{clip(removeMarkdown(post.content), 120)}</Text>
        <Icon src={arrowRightImg} alt="weiter" />
      </Box>
    </Link>
  )
}
